import type { TaxonomiesFieldsPivot } from 'src/fields/taxonomies';
import type { Dependencies } from 'src/fields/taxonomies/dependencies';
import type { PivotsMap } from '../types';

const getDependencyIndexFactory =
  (fieldToMap: PivotsMap) =>
  (dependency: { id: string }): number => {
    const pivots = fieldToMap[dependency.id];
    if (!pivots) {
      return -1;
    }
    return Math.max(
      ...pivots.map((pivot) => {
        return pivot.index;
      }),
    );
  };

export default ({
  dependencies,
  index,
  pivots,
  fieldToMap,
}: {
  dependencies: Dependencies & { keyValues?: Dependencies[] };
  index: number;
  pivots: TaxonomiesFieldsPivot[];
  fieldToMap: PivotsMap;
}): TaxonomiesFieldsPivot[] => {
  if (!dependencies.dependencies?.length && !dependencies.keyValues?.length) {
    return [];
  }

  const getDependencyIndex = getDependencyIndexFactory(fieldToMap);

  const lastDependencyIndex = Math.max(
    index,
    ...(dependencies.dependencies?.map(getDependencyIndex) ?? []),
    ...(dependencies.keyValues ?? []).map((dependency) => {
      return Math.max(
        ...(dependency.dependencies?.map((value) => {
          return getDependencyIndex(value);
        }) ?? []),
      );
    }),
  );

  if (lastDependencyIndex <= index) {
    return [pivots[index]];
  }

  return pivots.slice(index, lastDependencyIndex + 1);
};
