import type { StandardField } from '../../../../fields/standard';
import type {
  Dependencies,
  TaxonomiesFieldsPivot,
} from '../../../../fields/taxonomies';
import getRegexByField from '../../../../processing/get-regex-by-field';
import {
  getRepeatOperator,
  type getValidRegexes,
} from '../../../../processing/validate-regex';
import type { PivotsMap } from '../types';
import checkDependencies from './check-dependencies';
import choicesWrapper from './choices-wrapper';
import findFutureDependencies from './find-future-dependencies';
import pushInvalid from './push-invalid';
import type { Result } from './types';

const getCacheKey = ({
  matches,
  pivot,
  invalidFields,
  index,
}: {
  matches: string[] | undefined;
  pivot: TaxonomiesFieldsPivot;
  invalidFields: number[];
  index: number;
}) =>
  `${pivot.field.id}@${matches?.join(',')}@${invalidFields.join(',')}@${index}`;

export default ({
  pivots,
  fieldToMap,
  validate,
  standardFields,
  text,
  options,
}: {
  standardFields: StandardField[];
  pivots: TaxonomiesFieldsPivot[];
  fieldToMap: PivotsMap;
  validate: ReturnType<typeof getValidRegexes>;
  text: string;
  options: Parameters<typeof getValidRegexes>[2];
}) => {
  const cache: Record<string, Result> = {};

  const reducer = (
    { regexes, invalidFields }: Result,
    pivot: TaxonomiesFieldsPivot,
    index: number,
  ): Result => {
    // const start = performance.now();
    let required = pivot.required;
    const regexAsString = `^${regexes
      .map(({ regex }) => regex)
      .concat(
        findFutureDependencies({
          dependencies: pivot.field.parameters as Dependencies,
          index,
          pivots,
          fieldToMap,
        })
          .reduce(
            (accumulator, currentPivot, currentIndex) => {
              if (invalidFields.length !== accumulator.invalidFields.length) {
                return {
                  regexes: accumulator.regexes,
                  invalidFields: accumulator.invalidFields,
                } as Result;
              }
              return currentIndex === 0
                ? ({
                    regexes: [
                      ...regexes,
                      {
                        regex: getRegexByField(
                          {
                            ...standardFields[index],
                            // required,
                          },
                          standardFields[index + 1],
                          true,
                        ),
                        valid: true,
                      },
                    ],
                    invalidFields,
                  } as Result)
                : reducer(accumulator, currentPivot, currentIndex + index);
            },
            { regexes, invalidFields } as Result,
          )
          .regexes.slice(index)
          .map((r, currentIndex) =>
            r.valid || currentIndex === 0 ? r.regex : '()',
          ),
      )
      .join('')}`;
    const [matched, ...matches] = new RegExp(regexAsString).exec(text) ?? [];

    const cacheKey = getCacheKey({ matches, pivot, invalidFields, index });

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }

    const orginalValid = matched !== undefined;
    let valid = orginalValid;
    let skip: boolean | undefined = false;

    // check root dependencies
    ({ valid, required, skip } = checkDependencies({
      map: fieldToMap,
      dependencies: pivot.field.parameters,
      asIndex: index,
      required,
      valid,
      matches,
      invalidFields,
      isChoice: false,
    }));

    if (!!skip) {
      const repeatOperator = getRepeatOperator(options);

      if (index === pivots.length - 1) {
        valid &&= new RegExp(
          `^${regexes.map(({ regex }) => regex).join('')}$`,
        ).test(text);
      }

      return {
        regexes: [
          ...regexes,
          {
            regex: index !== pivots.length - 1 ? '()' : `(.${repeatOperator})`,
            valid,
            match: matches[index],
          },
        ],
        invalidFields: pushInvalid(valid, invalidFields, index),
        matches: [...matches, ''],
      };
    }

    const newRegexes = choicesWrapper({
      required,
      valid,
      pivot,
      standardField: standardFields[index],
      standardFields,
      validate,
      index,
      regexes,
      matches,
      map: fieldToMap,
      invalidFields,
    });

    valid &&= newRegexes[index].valid;

    const result = {
      regexes: newRegexes,
      invalidFields: pushInvalid(
        newRegexes[index - 1]?.valid ?? true,
        pushInvalid(valid, invalidFields, index),
        index - 1,
      ),
      matches,
    };

    if (valid) {
      cache[cacheKey] = result;
    }

    // console.error(
    //   'reducer',
    //   index,
    //   performance.now() - start,
    //   newRegexes[index].regex,
    // );

    return result;
  };

  return reducer;
};
