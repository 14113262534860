import type { TaxonomiesFieldsPivot } from 'src';

import type { Options } from '../../validate-regex';
import { getReturnText } from '../../validate-regex';

import type { ValidationResult } from './types';

import twoPass from '../two-pass';
import process from './process';
export type { ValidationResult } from './types';

/**
 * @typedef { import("./validate-regex").Options } Options
 * @typedef { import("./validate-taxonomy-regex").ValidationResult } ValidationResult
 */

/**
 * Validate taxonomies regex
 * @param {TaxonomiesFieldsPivot[]} pivots
 * @param {string} text
 * @param {Options} [options={}]
 *
 * @returns {ValidationResult} invalidFields, returnText, matches
 */
export default (
  pivots: TaxonomiesFieldsPivot[],
  text: string,
  options: Options = {},
): ValidationResult => {
  if (options.twoPass) {
    return twoPass(pivots, text, options);
  }
  const result = process(pivots, text, options);

  const regexp = `^${result.regexes.map(({ regex }) => regex).join('')}$`;
  const [, ...matches] = new RegExp(regexp, 'm').exec(text) || [];

  return {
    fieldsIds: pivots.map(({ field }) => field.id),
    invalidFields: result.invalidFields,
    returnText: getReturnText(matches, result.invalidFields),
    matches: matches.map((match) => match ?? ''),
    ...(options.debug
      ? {
          matchesWithKeys: pivots.map((pivot, index) => {
            const field = pivot.field;
            return [
              index,
              field.label,
              matches[index] || '',
              field.type,
              JSON.stringify(field.parameters),
            ];
          }),
        }
      : {}),
  };
};
