import { convertTaxonomiesToStandards } from '../../../../fields/converters';
import type { TaxonomiesFieldsPivot } from '../../../../fields/taxonomies';
import {
  type Options,
  getValidRegexes,
} from '../../../../processing/validate-regex';
import type { ValidRegex } from '../../../../processing/validate-regex/types';
import type { PivotsMap } from '../types';
import reducerFactory from './reducer-factory';
import type { Result } from './types';

export default (
  pivots: TaxonomiesFieldsPivot[],
  text: string,
  options: Options = {},
) => {
  const standardFields = convertTaxonomiesToStandards(pivots);

  const fieldToMap = pivots.reduce<PivotsMap>((acc, pivot, index) => {
    acc[pivot.field.id] = acc[pivot.field.id] ?? [];
    acc[pivot.field.id].push({
      index,
      ...pivot,
    });

    return acc;
  }, {});

  // const backCheck: Record<
  //   string,
  //   ({ id: string; index: number } & Dependencies)[]
  // > = {};
  const validate = getValidRegexes(standardFields, text, {
    ...options,
  });

  const reducer = reducerFactory({
    fieldToMap,
    validate,
    pivots,
    standardFields,
    text,
    options,
  });

  return pivots.reduce<Result>(reducer, {
    invalidFields: [] as number[],
    regexes: [] as ValidRegex[],
    matches: [] as string[],
  });
};
