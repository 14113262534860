import { type ValidationResult, pivotDbToApi, validateTaxonomyRegex } from '..';

import type { Options } from './validate-regex';

import type { Template } from '@grasp-gg/lib-core-prisma-taxonomies';

import type {
  TaxonomiesFieldsPivot,
  TemplatesFieldsPivotFromDataBase,
} from '..';

export type TemplateWithFields = Template & {
  fields: TemplatesFieldsPivotFromDataBase[];
  apiFields?: TaxonomiesFieldsPivot[];
};

/**
 * @typedef { import("./validate-regex").Options } Options
 * @typedef { import("./validate-taxonomy-regex").ValidationResult } ValidationResult
 */

export const convertTemplateFields = (
  template: TemplateWithFields | undefined,
) => {
  return template?.apiFields
    ? template.apiFields
    : pivotDbToApi(template?.fields || []);
};

/**
 * Validate taxonomies regex
 * @param {TemplateWithFields[]} template
 * @param {string} text
 * @param {Options} [options={}]
 *
 * @returns {ValidationResult} invalidFields, returnText, matches
 */
export default (
  template: TemplateWithFields | undefined,
  text: string,
  options: Options = {},
): ValidationResult => {
  return validateTaxonomyRegex(convertTemplateFields(template), text, options);
};
