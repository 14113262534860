import escapeRegExp from 'lodash/escapeRegExp';

import { unique } from 'radash';
import type { StandardField } from '../../fields';
import getAnythingOptions from './by-field-type-anything';
import getDateOptions from './by-field-type-date';
import getTextOptions from './by-field-type-text';

// eslint-disable-next-line complexity
export default (field: StandardField): string => {
  switch (field.type) {
    case 'number':
      return '[0-9]';
    case 'text':
      return getTextOptions(field);
    case 'date':
      return getDateOptions(field);
    case 'fixed':
      return `(?:${escapeRegExp(field.value)}){1}`;
    case 'choice':
    case 'checkbox':
      return unique(
        (field.keyValues ?? [])
          .map(({ value }) => escapeRegExp(value))
          .concat(field.defaultValue ? [field.defaultValue] : []),
      )
        .sort((a, b) => {
          if (a.length < b.length) {
            return 1;
          }

          return -1;
        })
        .join('|');
    case 'anything':
      return getAnythingOptions(field);
    case 'url':
      return 'https?://(?:www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)';
    default:
      return '.';
  }
};
