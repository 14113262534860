import { MAX_CHOICE_LENGTH } from '../../../../fields/converters/taxonomy-to-standard-factory';
import type { StandardField } from '../../../../fields/standard';
import type { TaxonomiesFieldsPivot } from '../../../../fields/taxonomies';
import type { getValidRegexes } from '../../../../processing/validate-regex';
import type { PivotsMap } from '../types';
import checkDependencies from './check-dependencies';
import type { Result } from './types';

export default ({
  required,
  valid,
  pivot,
  standardField,
  validate,
  index,
  regexes,
  matches,
  map,
  invalidFields,
}: {
  required: boolean;
  valid: boolean;
  pivot: TaxonomiesFieldsPivot;
  standardField: StandardField;
  standardFields: StandardField[];
  validate: ReturnType<typeof getValidRegexes>;
  index: number;
  regexes: Result['regexes'];
  matches: string[];
  map: PivotsMap;
  invalidFields: number[];
}) => {
  // FIXME: doesnt check if choice is present when max is reached
  if (
    !(valid && pivot.field.type === 'choice' && standardField.type === 'choice')
  ) {
    return validate(
      regexes,
      {
        ...standardField,
        required: required && valid,
      },
      index,
    );
  }
  const choices =
    pivot.field.parameters.keyValues.length <= MAX_CHOICE_LENGTH
      ? pivot.field.parameters.keyValues.filter((choice) => {
          const { valid: keep } = checkDependencies({
            map,
            dependencies: choice,
            asIndex: index,
            required,
            valid,
            matches,
            invalidFields,
            isChoice: true,
          });

          return keep;
        })
      : standardField.keyValues;

  // check choice dependencies
  if (
    required &&
    !choices.length &&
    pivot.field.parameters.keyValues.length <= MAX_CHOICE_LENGTH &&
    !(pivot.field.parameters.freeValue && !pivot.field.parameters.saveFreeValue)
  ) {
    valid &&= false;
  }

  const newRegexes = validate(
    regexes,
    {
      ...standardField,
      keyValues: valid
        ? choices.length <= MAX_CHOICE_LENGTH
          ? choices
          : standardField.keyValues
        : [{ key: 'error', value: 'error' }],
      required: required || !valid,
    },
    index,
  );

  if (
    newRegexes[index].valid &&
    pivot.field.parameters.keyValues?.length > MAX_CHOICE_LENGTH
  ) {
    const choice = pivot.field.parameters.keyValues?.find(
      (choice) => choice.value === newRegexes[index].match,
    );

    if (choice) {
      const { valid: choiceValid } = checkDependencies({
        map,
        dependencies: choice,
        asIndex: index,
        required,
        valid,
        matches,
        invalidFields,
        isChoice: true,
      });
      valid &&= choiceValid;
    } else {
      valid = false;
    }
  }

  return [
    ...newRegexes.slice(0, index),
    {
      ...newRegexes[index],
      valid: valid && newRegexes[index].valid,
    },
  ];
};
