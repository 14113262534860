import type { FieldInclude, StandardField } from '..';

import type { TaxonomiesFieldsPivot } from '../taxonomies';

const removeDependencies = <T extends { dependencies?: unknown }>(
  parameters: T,
): Omit<T, 'dependencies'> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { dependencies, ...rest } = parameters;

  return rest;
};

// dont change it's breaking l'oreal:
// org_pg4p84g7g8381asr5g702r18 / 5623092777119552 @dv
export const MAX_CHOICE_LENGTH = 1000;

const choiceToAnything = (
  field: StandardField & { type: 'choice' },
  separator = '_',
): StandardField & { type: 'choice' } => {
  let min = field.keyValues[0].value?.length ?? 0;
  let max = 0;
  const characters = new Set();
  let include: Partial<FieldInclude> | undefined = {};

  if (field.freeValue && !field.saveFreeValue) {
    min = field.min || 1;
    max = field.max || 250;
    include = field.include;
  }

  field.keyValues.forEach(({ value }) => {
    if (value && !value.includes(separator)) {
      min = Math.min(min, value.length);
      max = Math.max(max, value.length);
      [...value].forEach((character) => characters.add(character));
    }
  });

  return {
    required: field.required,
    label: field.label,
    description: field.description,
    type: 'choice',
    freeValue: true,
    keyValues: field.keyValues.filter(({ value }) => value.includes(separator)),
    min,
    max,
    include: {
      ...include,
      // we list all characters used in the choices
      characters: [...characters, ...(include?.characters ?? [])].join(''),
    },
  } as StandardField & { type: 'choice' };
};

// eslint-disable-next-line complexity
export default (realCheck: boolean) =>
  (
    taxonomyFieldPivot: Omit<
      TaxonomiesFieldsPivot,
      'position' | 'displayPosition' | 'id'
    > & { position?: number; id?: string | null },
  ): StandardField => {
    const field = taxonomyFieldPivot.field;
    const required = field.parameters.dependencies
      ? realCheck && taxonomyFieldPivot.required
      : taxonomyFieldPivot.required;
    const base = {
      required,
      label: field.label,
      description: field.description,
    };
    const { type, parameters } = field;

    switch (type) {
      case 'anything':
        return {
          ...base,
          ...removeDependencies(parameters),
          type,
        };

      case 'choice':
        const choice = {
          ...base,
          required: parameters.keyValues.some(
            (keyValue) => !!keyValue.dependencies,
          )
            ? realCheck
            : required,
          ...removeDependencies(parameters),
          type,
        };

        if (choice.keyValues.length > MAX_CHOICE_LENGTH) {
          return choiceToAnything(choice);
        }

        return choice;

      case 'fixed':
        return {
          ...base,
          ...removeDependencies(parameters),
          type,
        };

      case 'number':
        return {
          ...base,
          ...removeDependencies(parameters),
          type,
        };

      case 'text':
        return {
          ...base,
          ...removeDependencies(parameters),
          type,
        };

      case 'date':
        return {
          ...base,
          ...removeDependencies(parameters),
          type,
        };

      case 'url':
        return {
          ...base,
          ...removeDependencies(parameters),
          type,
        };

      case 'checkbox':
        return {
          ...base,
          ...removeDependencies(parameters),
          type,
        };

      case 'template':
        return {
          ...base,
          ...removeDependencies(parameters),
          type,
          templateId: field.templateId,
          template: field.template,
        };

      default:
        throw new Error(`Unknown type ${type}`);
    }
  };
