/* eslint-disable complexity */
import { intersection, unescape } from 'lodash';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import { MAX_CHOICE_LENGTH } from '../../fields/converters/taxonomy-to-standard-factory';
import getRegexByField from '../get-regex-by-field';
import { containsExcludedWords } from '../get-regex-by-field/by-field-type-anything';
import validateRegex, { getReturnText } from '../validate-regex';

import {
  type Dependencies,
  type TaxonomiesFieldsPivot,
  convertTaxonomiesToStandards,
} from '../../fields';

import type { Options } from '../validate-regex';

type PivotMap = {
  [key: string]: {
    index: number;
  } & TaxonomiesFieldsPivot;
};

export type ValidationResult = {
  invalidFields: number[];
  returnText: string;
  fieldsIds?: string[];
  matches: string[];
};

/**
 * @typedef { import("./validate-regex").Options } Options
 * @typedef { import("./validate-taxonomy-regex").ValidationResult } ValidationResult
 */

/**
 * Validate taxonomies regex
 * @param {TaxonomiesFieldsPivot[]} pivots
 * @param {string} text
 * @param {Options} [options={}]
 *
 * @returns {ValidationResult} invalidFields, returnText, matches
 */
export default (
  pivots: TaxonomiesFieldsPivot[],
  text: string,
  options: Options = {},
): ValidationResult => {
  const standardFields = convertTaxonomiesToStandards(pivots);
  // const regexes = standardFields.reduce(
  //   getValidRegexes(standardFields, text, {
  //     ...options,
  //   }),
  //   [],
  // );
  const validatedRegex = validateRegex(standardFields, text, {
    ...options,
    returnRegexes: true,
  });
  const regexes = validatedRegex.validRegexes ?? [];

  const fieldToMap = pivots.reduce<PivotMap>((acc, pivot, index) => {
    acc[pivot.id] = {
      index,
      ...pivot,
    };

    return acc;
  }, {});

  const result = pivots.reduce(
    ({ invalidFields, returnText, matches }, pivot, index) => {
      const orginalValid = !invalidFields.find(
        (fieldIndex) => fieldIndex === index,
      );
      let valid = orginalValid;
      let required = pivot.required;

      const matched = unescape(matches[index]);

      const checkDependencies = (
        map: PivotMap,
        { dependencies }: Dependencies,
        debug?: boolean,
      ): {
        valid: boolean;
        required: boolean;
      } => {
        if (!dependencies?.length) {
          return {
            valid,
            required,
          };
        }
        const meaningfullDependencies = dependencies
          // make sure the dependency is used in another pivot
          .filter(
            (dependency) =>
              !!Object.values(map).find(
                (pivot) => pivot.field.id === dependency.id,
              ),
          );

        // if the dependency is not present, it is not required
        // even if the field is required in the template
        if (!meaningfullDependencies?.length) {
          return {
            valid,
            required: false,
          };
        }

        return {
          valid: meaningfullDependencies
            // make sure all dependencies are matched
            .every(({ id, values, exclude }) => {
              if (debug) {
                console.error(map);
                console.error(meaningfullDependencies);
                debugger;
              }

              const dependencyPivots = Object.values(map).filter(
                (pivot) => pivot.field.id === id,
              );

              const linkedFieldValues = dependencyPivots.map(({ index }) =>
                unescape(matches[index]),
              );

              // at least one of the values must be matched
              if (!values.length) {
                // empty means catch all
                // dependency should not error
                // if dependency is empty, so should be current field

                const invalidDependencyFields = intersection(
                  invalidFields,
                  dependencyPivots.map(({ index }) => index),
                );

                if (exclude) {
                  return (
                    valid &&
                    !invalidDependencyFields.length &&
                    !linkedFieldValues.filter((v) => !!v).length === !!matched
                  );
                }

                return (
                  valid &&
                  !invalidDependencyFields.length &&
                  !!linkedFieldValues.filter((v) => !!v).length === !!matched
                );
              }

              const foundValues = values.some((value) =>
                linkedFieldValues.includes(value),
              );

              if (exclude) {
                return !foundValues;
              }

              return foundValues;
            }),
          // required if every dependencies are matched
          // TODO: Add test with multi dependencies
          required: meaningfullDependencies.every(({ id, values, exclude }) => {
            const linkedFieldValues = Object.values(map)
              .filter((pivot) => pivot.field.id === id)
              .map(({ index }) => unescape(matches[index]));

            if (!values.length) {
              // [] means any value in the parent field

              if (exclude) {
                return !linkedFieldValues.filter((v) => !!v).length;
              }

              return !!linkedFieldValues.filter((v) => !!v).length;
            }

            const foundValues = values.some((value) =>
              linkedFieldValues.includes(value),
            );

            if (exclude) {
              return !foundValues;
            }

            return foundValues;
          }),
        };
      };

      // check root dependencies
      ({ valid, required } = checkDependencies(
        fieldToMap,
        pivot.field.parameters,
      ));

      if (
        valid &&
        standardFields[index].type === 'anything' &&
        standardFields[index].exclude
      ) {
        valid = !containsExcludedWords(standardFields[index], matched);
      }

      if (pivot.field.type === 'choice') {
        const choices = pivot.field.parameters.keyValues.filter(
          ({ value }) => value === matched,
        );

        // check choice dependencies
        if (choices.length) {
          ({ valid, required } = choices.reduce<{
            valid: boolean;
            required: boolean;
          }>(
            (acc, choice) => {
              const data = checkDependencies(fieldToMap, choice);

              return {
                valid: acc.valid || data.valid,
                required: acc.required || data.required,
              };
            },
            {
              valid: false,
              required: false,
            },
          ));
        } else if (
          pivot.field.parameters.keyValues.length > MAX_CHOICE_LENGTH &&
          !(
            pivot.field.parameters.freeValue &&
            !pivot.field.parameters.saveFreeValue
          )
        ) {
          // valid is not relevant because the choice doesn't exist
          valid = !required;
        } else {
          valid = (valid && matched !== '') || !required;
        }
      } else {
        // if the field didnt match and is not required, it still is valid
        valid = !valid ? matched === '' && !required : valid;

        // check if we can find an empty field and shift all matches to it
        // ie. 'a', 'b', '', 'c' => '', 'a', 'b', 'c'
        if (!valid && matched !== '' && !required) {
          const nextEmptyIndex = findIndex(
            matches,
            (m) => m === '' || m === undefined,
            index,
          );

          if (nextEmptyIndex > -1) {
            const regexesCopy = [...regexes];

            regexesCopy.splice(nextEmptyIndex, 1, {
              regex: getRegexByField(
                {
                  ...standardFields[nextEmptyIndex],
                  required: true,
                },
                null,
                true,
              ),
              valid: false,
              // not needed
              match: '',
            });
            regexesCopy.splice(index, 1, {
              regex: '()',
              valid: false,
              // not needed
              match: '',
            });
            const newResult = new RegExp(
              `^${regexesCopy.map(({ regex }) => regex).join('')}$`,
              'm',
            ).exec(text);

            if (newResult) {
              [, ...matches] = newResult;
              valid = true;
            }
          }
        }
      }

      return {
        invalidFields: !valid
          ? sortBy(uniq([...invalidFields, index]))
          : invalidFields,
        returnText,
        matches,
      };
    },
    validatedRegex,
  );

  return {
    fieldsIds: pivots.map(({ field }) => field.id),
    invalidFields: result.invalidFields,
    returnText: getReturnText(result.matches, result.invalidFields),
    matches: result.matches,
    ...(options.debug
      ? {
          matchesWithKeys: result.matches.map((match, index) => {
            const field = pivots[index].field;
            return [
              index,
              field.label,
              match,
              field.type,
              JSON.stringify(field.parameters),
            ];
          }),
        }
      : {}),
  };
};
