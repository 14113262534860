import type { StandardField } from '../../fields';
import isStaticField from './is-static';

// eslint-disable-next-line complexity
export default (
  field: StandardField,
  nextField: StandardField | null,
): string => {
  if (!isStaticField(field)) {
    const prefixSuffixLength =
      (field.prefix?.length || 0) + (field.suffix?.length || 0);

    const min = (field.min || 1) - prefixSuffixLength;
    const max = field.max ? field.max - prefixSuffixLength : '';

    // lazy `?` will allow choices to have a greater priority over free text
    return `{${min},${max}}${
      // if it's a choice, it should have more priority
      !('inChoice' in field) &&
      nextField &&
      isStaticField(nextField) &&
      nextField.required
        ? '?'
        : ''
    }`;
  }

  if (field.required === false) {
    return '?';
  }

  return '';
};
