import { sort, unique } from 'radash';

export default (
  valid: boolean,
  invalidFields: number[],
  index: number,
): number[] => {
  if (!valid) {
    return sort(unique([...invalidFields, index]), Number);
  }
  return invalidFields;
};
