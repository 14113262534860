import escapeRegExp from 'lodash/escapeRegExp';

import type { StandardField } from '../../../../fields';
import type { ValidRegex } from '../../types';

const getOptimizedRegex = (
  fields: StandardField[],
  index: number,
  newRegex: string,
  result: RegExpExecArray,
) => {
  return `(${[
    escapeRegExp(result.groups?.new ?? ''),
    ...[
      // we check back up until 2 fields
      1, 2,
    ]
      .filter(
        (i) =>
          fields[index - i] &&
          // if the previous field is optional
          !fields[index - i]?.required &&
          // then the current field could match
          result[index - i + 1]?.match(newRegex),
      )
      .map((i) => escapeRegExp(result[index - i + 1])),
  ].join('|')})`;
};

const getOptimizedUrlRegex = (_regex: string, result: string) => {
  return result.includes('?')
    ? `(${result.split('?')}[?](?:[-a-zA-Z0-9()@:%_+.~#?&/=]*))`
    : `(${result})`;
};

/**
 * @returns [valid, currentRegexes, newRegex] | undefined
 **/
// eslint-disable-next-line complexity
export default (
  fields: StandardField[],
  text: string,
  field: StandardField,
  index: number,
  currentRegexes: ValidRegex[],
  allRegex: string,
  newRegex: string,
): undefined | [boolean, ValidRegex[], string, string] => {
  if (field.type === 'url') {
    const [result, ...matches] = new RegExp(allRegex).exec(text) ?? [];

    if (result) {
      return [
        true,
        currentRegexes,
        getOptimizedUrlRegex(newRegex, result),
        matches[index],
      ];
    }
  } else if (field.type === 'choice' && !field.freeValue) {
    const result = new RegExp(allRegex).exec(text);

    if (result) {
      return [
        true,
        currentRegexes,
        result.groups?.new
          ? getOptimizedRegex(fields, index, newRegex, result)
          : newRegex,
        result[index + 1],
      ];
    }
  } else {
    const valid = new RegExp(allRegex).exec(text);

    if (valid || !fields[index - 1] || field.type !== 'fixed') {
      return [!!valid, currentRegexes, newRegex, valid?.[index + 1] ?? ''];
    }
  }

  return undefined;
};
