import unescape from 'lodash/unescape';
import { objectify } from 'radash';
import type { Dependencies } from 'src/fields/taxonomies/dependencies';
import type { PivotsMap } from '../../types';

export default ({
  map,
  dependencies: { dependencies: dependencies },
  asIndex,
  required,
  valid,
  matches,
  invalidFields,
  isChoice,
}: {
  map: PivotsMap;
  dependencies: Dependencies;
  asIndex: number;
  required: boolean;
  valid: boolean;
  matches: string[];
  invalidFields: number[];
  isChoice: boolean;
}): {
  valid: boolean;
  // keep: boolean;
  skip?: boolean;
  required: boolean;
} => {
  if (!dependencies?.length) {
    return {
      // valid,
      valid: true,
      required,
    };
  }
  const invalidMaps = objectify(invalidFields, (a) => a);
  const meaningfullDependencies = dependencies
    .map((dependency) => {
      return {
        ...dependency,
        dependencyPivots: map[dependency.id],
      };
    })
    // make sure the dependency is used in another pivot
    .filter((dependency) => !!dependency.dependencyPivots)
    .map((dependency) => {
      return {
        ...dependency,
        hasInvalidDependency: dependency.dependencyPivots.find(
          (pivot) => invalidMaps[pivot.index],
        ),
        linkedFieldValues: dependency.dependencyPivots.map(({ index }) =>
          unescape(matches[index]),
        ),
      };
    });

  // if the dependency is not present, it is not required
  // even if the field is required in the template
  if (!meaningfullDependencies?.length) {
    return {
      // valid,
      valid: true,
      required: false,
    };
  }

  const matched = unescape(matches[asIndex]);

  const newRequired = meaningfullDependencies
    // make sure all dependencies are matched
    .every(({ linkedFieldValues, values, exclude, hasInvalidDependency }) => {
      if (hasInvalidDependency) {
        return false;
      }

      if (!values.length) {
        // [] means any value in the parent field

        if (exclude) {
          return !linkedFieldValues.filter((v) => !!v).length;
        }

        return !!linkedFieldValues.filter((v) => !!v).length;
      }

      const foundValues = values.some((value) =>
        linkedFieldValues.includes(value),
      );

      if (exclude) {
        return required && !foundValues;
      }

      return required && foundValues;
    });

  const newValid = meaningfullDependencies
    // make sure all dependencies are matched
    .every(
      ({
        values,
        exclude,
        // dependencyPivots,
        hasInvalidDependency,
        linkedFieldValues,
      }) => {
        // at least one of the values must be matched
        if (!values.length) {
          // empty means catch all
          // dependency should not error
          // if dependency is empty, so should be current field

          if (exclude) {
            return (
              valid &&
              !hasInvalidDependency &&
              !linkedFieldValues.filter((v) => !!v).length === !!matched
            );
          }

          return (
            valid &&
            !hasInvalidDependency &&
            !!linkedFieldValues.filter((v) => !!v).length === !!matched
          );
        }

        const foundValues = values.some((value) =>
          linkedFieldValues.includes(value),
        );

        return exclude ? !foundValues : foundValues;
      },
    );

  return {
    valid: isChoice || newValid ? newValid : !newRequired,
    skip: !isChoice && !newValid && !newRequired,
    required: newRequired,
  };
};
