import { useApiStore } from '../../../store/api';

export async function saveExtensionUsage(
  organizationId: string,
  userId: string,
) {
  const apiStore = useApiStore();

  return apiStore.requestApiAccounts(
    `/organizations/${organizationId}/users/${userId}/excel-usage`,
    {
      method: 'PATCH',
    },
  );
}

export default saveExtensionUsage;
